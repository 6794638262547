import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tag',
  template: `
    <p>
      tag works!
    </p>
  `,
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
